@import url('https://fonts.googleapis.com/css?family=Raleway');

body {
  margin: 0;
  padding: 0;

  color: #444;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

h1,h2,h3,h4 {
  font-weight: 400;
  color: black;
}
