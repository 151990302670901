@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  padding: 0;

  color: #444;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

h1,h2,h3,h4 {
  font-weight: 400;
  color: black;
}

.App {
  text-align: center;
}

.App-input-section {
  margin-top: 80px;
}

.App-input {
  font-size: 2rem;
}
.App-input::-webkit-input-placeholder {
  font-size: 1rem;
}
.App-input::-ms-input-placeholder {
  font-size: 1rem;
}
.App-input::placeholder {
  font-size: 1rem;
}




.App-ouput-section {
  min-height: 250px;
  margin-top: 80px;
}
@media only screen and (max-width : 768px) {
  .App-ouput-section {
    margin-top: 10px;
  }
}

.App-empty-message {
  margin-top: 100px;
}

hr {
  width: 400px;
  margin: 80px auto 40px auto;
  border: 1px dashed rgba(34, 20, 41, 0.4);
}
@media only screen and (max-width : 768px) {
  hr {
    margin: 20px auto 10px auto;
  }
}

.App-info-section {
}

.App-info-nail-care {
  margin-top: 20px;
  font-size: 2rem;
}
.Decoded {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

}
.CodePoint {
	padding: .25rem;
	margin: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);

	-webkit-transition: all 200ms;

	transition: all 200ms;
}
.CodePoint:nth-of-type(2n+1) {
	background-color: rgba(0, 0, 0, .025);
}
.CodePoint:hover {
	background-color: rgba(61, 225, 195, 0.1);
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
}

.CodePoint-code-point {
	white-space: pre;
	font-size: 2rem;
}

.CodePoint-utf8-bytes {
	display: flex;
	justify-content: center;
}
.Utf8Byte {
	margin: .1rem;
	padding: 1rem;
	font-family: 'monospace';
}
.Utf8Byte:hover {
	background-color: rgba(0, 0, 0, .05);
}
.Utf8Byte--continuation {
}
.Utf8Byte--0 {
	color: red;
}
.Utf8Byte--1 {
	color: green;
}
.Utf8Byte--2 {
	color: blue;
}
.Utf8Byte--3 {
	color: purple;
}

.Utf8Byte-hex {
	text-transform: capitalize;
}

.Utf8Byte-encoding-part {
	color: #444;
	cursor: help;
	text-decoration: underline;
}
.Utf8Byte-encoding-part:hover {
	background: #444;
	color: white;
}
